import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGet,
  apiGetAuthenticated,
  apiPatchAuthenticated,
  apiPatchFileAuthenticated,
  apiPost,
  apiPostAuthenticated,
  apiPutAuthenticated,
  apiDeleteAuthenticated,
} from "../../services/api/apiService";
import { getMe } from "../app/appThunks";

export const getPatientNotes = createAsyncThunk(
  "patient/getPatientNotes",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/notes/?patient=${payload.patient}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updatePatientProfile = createAsyncThunk(
  "patient/updatePatientProfile",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchAuthenticated(
      `/patient/${payload.id}/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updatePatientPassword = createAsyncThunk(
  "patient/updatePatientPassword",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/change_password/change/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const saveSingleMessage = createAsyncThunk(
  "patient/saveSingleMessage",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(`/api/chat-history/`, payload);
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getMessageHistory = createAsyncThunk(
  "patient/getMessageHistory",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/chat-history-unread/filter-chat/?page=${payload.page}`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getUpcomingAppointments = createAsyncThunk(
  "patient/getUpcomingAppointments",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/upcoming_appointment/upcoming-appointment/`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getAppointmentsList = createAsyncThunk(
  "patient/getAppointmentsList",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/appointments/list/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getAllSessionsList = createAsyncThunk(
  "patient/getAllSessionsList",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/appointments/list-session/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getSessionsList = createAsyncThunk(
  "patient/getSessionsList",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/appointments/list-session/?therapist_id=${payload.id}${
        payload.slotId ? `&slot_id=${payload.slotId}` : ""
      }${payload.serviceId ? `&service_id=${payload.serviceId}` : ""}`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getPackages = createAsyncThunk(
  "patient/getPackages",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist/${payload.id}/packages/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const scheduleNewAppointment = createAsyncThunk(
  "patient/scheduleNewAppointment",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(`/api/appointment/`, payload);
    if (response.status === 200 || response.status === 201)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const patchAppointment = createAsyncThunk(
  "patient/patchAppointment",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchAuthenticated(
      `/api/appointment/${payload.id}/`,
      payload
    );
    if (response.status === 200 || response.status === 201)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const deleteAppointment = createAsyncThunk(
  "patient/deleteAppointment",
  async (payload, { rejectWithValue }) => {
    const response = await apiDeleteAuthenticated(
      `/api/appointments/cancel/${payload.id}/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTimeZoneOptions = createAsyncThunk(
  "patient/getTimeZoneOptions",
  async (payload, { rejectWithValue }) => {
    const response = await apiGet(`/timezone/options/`);
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const listAvailableSlots = createAsyncThunk(
  "patient/listAvailableSlots",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/appointment_schedule/list-available-slots/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);
export const listAvailableSlotsUnAuth = createAsyncThunk(
  "patient/listAvailableSlotsUnAuth",
  async (payload, { rejectWithValue }) => {
    const response = await apiPost(
      `/api/appointment_schedule/list-available-slots/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getCardDetails = createAsyncThunk(
  "patient/getCardDetails",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payment/get-card-details/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistTierPackage = createAsyncThunk(
  "patient/getTherapistTierPackage",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payment/get-therapist-tier-package/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const cancelSubscription = createAsyncThunk(
  "patient/cancelSubscription",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/payment/cancel-payment/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const oneTimeRecharge = createAsyncThunk(
  "patient/oneTimeRecharge",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payment/create-onetime-payment-recharge/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const payOneTime = createAsyncThunk(
  "patient/payOneTime",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payment/recharge-onetime-package/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);
export const getBillingRedirect = createAsyncThunk(
  "patient/getBillingRedirect",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/payment/get-stripe-billing/`
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getAllAppointmentsPatient = createAsyncThunk(
  "patient/getAllAppointmentsPatient",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/upcoming_appointment/past-appointment/`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const patchSingleMessage = createAsyncThunk(
  "patient/patchSingleMessage",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchAuthenticated(
      `/api/chat-history/${payload.id}/`,
      payload
    );
    if (response.status === 200 || response.status === 201)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const saveBillingDetails = createAsyncThunk(
  "patient/saveBillingDetails",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/set_billing_detail/store-details/`,
      payload
    );
    if (response.status === 200 || response.status === 201)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updatePatientPicture = createAsyncThunk(
  "patient/updatePatientPicture",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchFileAuthenticated(
      `/patient/${payload.id}/`,
      payload.formdata
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const applyCouponOneTimeRecharge = createAsyncThunk(
  "patient/applyCouponOneTimeRecharge",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      "/api/patient_onboarding/apply-coupon/",
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const applyCouponUpgradeRecharge = createAsyncThunk(
  "patient/applyCouponUpgradeRecharge",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      "/api/patient_onboarding/apply-coupon/",
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const upgradeRecharge = createAsyncThunk(
  "patient/upgradeRecharge",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payment/create-subscription-payment/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const payUpgrade = createAsyncThunk(
  "patient/payUpgrade",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payment/confirm-change-to-subscription/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistList = createAsyncThunk(
  "patient/getTherapistList",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist-match/therapist-list/`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const switchPatientTherapist = createAsyncThunk(
  "patient/switchPatientTherapist",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/therapist-match/change-therapist/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getRandomSetupIntent = createAsyncThunk(
  "patient/getRandomSetupIntent",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/add-payment/add-payment/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const videoCallLog = createAsyncThunk(
  "patient/videoCallLog",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(`/api/video-log/`, payload);
    if (response.status === 200 || response.status === 201)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);
export const submitPatientProfile = createAsyncThunk(
  "patient/submitPatientProfile",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await apiPutAuthenticated(
      `/api/patient/profile/`,
      payload
    );
    if (response.status === 200) {
      dispatch(getMe());
      return await response.json();
    } else return rejectWithValue(await response.json());
  }
);

export const deleteMedia = createAsyncThunk(
  "onboarding/deleteMedia",
  async (payload, { rejectWithValue }) => {
    const response = await apiDeleteAuthenticated(
      `/api/media/${payload.id}/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const rescheduleSession = createAsyncThunk(
  "patient/rescheduleSession",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/appointment/${payload.package_id}/schedules/`,
      payload?.selected_slot
    );
    if (response.status === 200) {
      window.location.reload();
      return await response.json();
    } else return rejectWithValue(await response.json());
  }
);

export const getPatientDashboard = createAsyncThunk(
  "patient/getPatientDashboard",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(`/api/patient/dashboard/`);
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getPatientDashboardStress = createAsyncThunk(
  "patient/getPatientDashboardStress",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/patient/dashboard/stress/?start_date=${payload.start_date}&end_date=${payload.end_date}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getPatientDashboardMood = createAsyncThunk(
  "patient/getPatientDashboardMood",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/patient/dashboard/mood/?start_date=${payload.start_date}&end_date=${payload.end_date}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const addPatientDashboardStress = createAsyncThunk(
  "patient/addPatientDashboardStress",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await apiPostAuthenticated(
      `/api/patient/dashboard/stress/`,
      payload
    );
    if (response.status === 200) {
      const res = await dispatch(getPatientDashboard());
      if (res) return await response.json();
    } else return rejectWithValue(await response.json());
  }
);

export const addPatientDashboardMood = createAsyncThunk(
  "patient/addPatientDashboardMood",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await apiPostAuthenticated(
      `/api/patient/dashboard/mood/`,
      payload
    );
    if (response.status === 200) {
      const res = await dispatch(getPatientDashboard());
      if (res) return await response.json();
    } else return rejectWithValue(await response.json());
  }
);
export const submitFeedback = createAsyncThunk(
  "patient/submitFeedback",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await apiPostAuthenticated(
      `/api/appointments/rating/${payload.appointment_id}/`,
      payload
    );
    if (response.status === 200) {
      dispatch(getPatientDashboard());
      return await response.json();
    } else return rejectWithValue(await response.json());
  }
);

export const getPatientSettings = createAsyncThunk(
  "patient/getPatientSettings",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(`/api/patient/settings/`);
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updatePatientSettings = createAsyncThunk(
  "patient/updatePatientSettings",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await apiPutAuthenticated(
      `/api/patient/settings/`,
      payload
    );
    if (response.status === 200) {
      dispatch(getPatientSettings());
      return await response.json();
    } else return rejectWithValue(await response.json());
  }
);

export const sendMessageToFitcyAIBot = createAsyncThunk(
  "patient/sendMessageToFitcyAIBot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await apiPostAuthenticated(`/api/ai-chat`, payload);

      // const response2 = {
      //   status: 200,
      //   user_message: {
      //     id: payload.sender,
      //     created: "2024-12-26T22:17:51.726390Z",
      //     modified: "2024-12-26T22:17:51.726441Z",
      //     message_type: "text",
      //     message: "My 2st message",
      //     read_status: true,
      //     thread: null,
      //     wp_msg_id: null,
      //     to: 9,
      //     sender: 6,
      //   },
      //   ai_response: {
      //     id: 48,
      //     created: "2024-12-26T22:17:51.732255Z",
      //     modified: "2024-12-26T22:17:51.732295Z",
      //     message_type: "text",
      //     message:
      //       "Here's some information about 'My 2st message' (mock response).",
      //     read_status: false,
      //     thread: null,
      //     wp_msg_id: null,
      //     to: payload.sender,
      //   },
      // };

      if (response.status === 200) {
        // let body = {
        //   message_type: "text",
        //   message: response.ai_response.message,
        //   read_status: false,
        //   sender: parseInt(process.env.REACT_APP_FITCY_BOT_ID),
        //   to: response.user_message.id,
        //   created: new Date(),
        // };

        //dispatch(saveSingleMessage(body));
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error) {
      return rejectWithValue({
        status: 500,
        message: "Error processing bot response",
        error: error.message,
      });
    }
  }
);
